<template>
  <div class="text-gray-800 border-t-4 border-blue-500 modal">
    <div class="px-4 py-5 bg-white border-b border-gray-200 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        Upload Content
      </h3>
    </div>

    <div class="px-8 py-4 modal-content bg-neutral-gray">
      <div class="grid-container content-upload">
        <div class="text-sm border-r content-upload-sidebar">
          <div class="flex items-center justify-start p-4 border-b">
            <fa-icon
              icon="upload"
              class="mr-2"
            /> <div>Upload from computer</div>
          </div>
        </div>

        <div class="content-upload-area">
          <div class="flex flex-col items-center justify-center p-12 m-4 bg-gray-200 border-4 border-dashed rounded-lg placeholder segment">
            <fa-icon
              icon="photo-film"
              size="3x"
              class="mb-4 text-gray-800"
            />
            <div class="mb-4 text-xl text-gray-800">
              Drop files here or click to upload.
            </div>
            <button class="btn primary">
              Upload Content
            </button>
          </div>
        </div>
      </div>

      <!-- <form class="mb-4">
        <div class="mb-6">
          <input class="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:ring" id="projectName" type="file" placeholder="">
        </div>
        <div class="flex items-center justify-end">
          <button class="btn primary" type="button">
            Upload
          </button>
        </div>
      </form> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ['text']
}
</script>
